import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import { Container } from "../components/UI"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Page not found" />
      <main className="bg-white">
        <Container>
          <div className="pt-20 text-center ">
            <StaticImage
              src="https://www.datocms-assets.com/54391/1644561239-not-found-image.png"
              alt="Toucan Page Not Found"
              className="mx-auto mb-20 lg:h-4/6 lg:w-4/6"
              placeholder="blurred"
            />
            <h1 className="mx-auto mb-10 font-spartan text-3xl text-gray-1 md:text-5xl lg:w-4/6">
              Oh no! We couldn't find what you were looking for!
            </h1>
          </div>
        </Container>
      </main>
    </Layout>
  )
}

export default NotFoundPage
